import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";
import { AntdLayout } from "@pankod/refine-antd";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <>

    <Link to="/">
      {collapsed ? (
        <img
          src={"mabi_logo-yellow-vertical.svg"}
          alt="Mabi"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            //          padding: "12px 24px",
          }}
        />
      ) : (<>
        <img
          src={"mabi_logo-yellow-vertical.svg"}
          alt="Mabi"
          style={{
            width: "100%",
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      
      
        </>)}
    </Link>
        
  </>
);
