import { Button, Card, Col, CreateButton, Form, FormProps, Grid, Icons, Input, List, Modal, RadioChangeEvent, Row, Select, Space, Table, TextField, Tooltip, Typography, getDefaultSortOrder, useModal, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, useShow, useTranslate } from "@pankod/refine-core";
import { IMekdata2, IMekdata2Filter } from "interfaces/mekdata2";
import { useEffect, useState } from "react";

const { useBreakpoint } = Grid;
const { Text, Link } = Typography;

type FilterSelectProps = {
    value?: IMekdata2;
    onSelect?: (value:any) => void;
    onChange?: (filter?: IMekdata2) => void;
    disabled?: boolean;
  };

  /**
  * @param value initialValue passed by parent Form.Item.
  * @param onChange a callback for Form.Item to read its child component's value.
  * @param disabled
  * @constructor
  */
 export const FilterSelect: React.FC<FilterSelectProps> = ({
    value,
    onSelect,
    onChange,
    disabled,
  }) => {
    const t = useTranslate();
    const [filter, setFilter] = useState<IMekdata2 | undefined>(value);
    const { tableProps, sorter, filters, searchFormProps: searchFormProps2 } = useTable<
      IMekdata2,
      HttpError,
      IMekdata2Filter
    >({
      resource: "data",
      initialSorter: [
        {
          field: "id",
          order: "desc",
        },
      ],

      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { tnev, mezokod, TNameExact, TNameStartsWith, MezokodExact } = params;

        filters.push({
          field: "tnev",
          operator: "eq",
          value: tnev,
        });

        filters.push({
          field: "TNameExact",
          operator:"eq",
          value: TNameExact || undefined,
        });

        filters.push({
          field: "TNameStartsWith",
          operator: "eq",
          value: TNameStartsWith || undefined,
        });

        filters.push({
          field: "MezokodExact",
          operator: "eq",
          value: MezokodExact,
        });
        return filters;
      },
      syncWithLocation: false,
    });
    const { xl } = useBreakpoint();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
      value?.id ?? "",
    ]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const start = () => {
      setLoading(true);
      // ajax request after empty completing
      setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      }, 1000);
    };

    const [loading, setLoading] = useState(false);

    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const {
      modalProps: filterSelectModal,
      show: filterSelectShow,
      close: filterSelectClose,
    } = useModal();

    const { setShowId, queryResult: filterQueryResult } = useShow<IMekdata2>({
      resource: "data",

      id: filter?.id,
    });

    let promise = new Promise(function (resolve, reject) {
      if (typeof onChange === "function") {
        resolve(onChange(filter));
      } else {
        resolve(true);
      }
    });
    useEffect(() => {
      if (value && filter?.id !== value?.id) {
        setFilter(value);
      }
    }, [value]);

    useEffect(() => {
      if (filterQueryResult.isFetched) {
        setFilter(filterQueryResult.data?.data);
      }
    }, [filterQueryResult]);

   useEffect(() => {
      if (filterQueryResult.isFetched && !filterQueryResult.isError) {
        promise.then(
          () =>{if (typeof onSelect === "function") {
          //alert(JSON.stringify(filter));
            onSelect(filter);
          }}

        ).
        then(filterSelectClose);
      }
    }, [filter]);

    const hasSelected = selectedRowKeys.length > 0;

    const change = async (selectedRowKeys: React.Key[]) => {
      if (filter?.id !== selectedRowKeys[0]) {
        await setShowId(selectedRowKeys[0]);
        if (typeof onSelect === "function") {
          //alert(JSON.stringify(filter));
          onSelect(value);
        }
     }
      else filterSelectClose();
    };

    return (
      <>
        <Space>
          <Tooltip
            title={
              <Card
                title={
                  <Space
                    direction="vertical"
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >

                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        textAlign: xl ? "unset" : "center",
                      }}
                    >
                      <Typography.Text>
                        {filter?.mezokod == 1 ? <> <Icons.HomeOutlined /> Születési hely<br></br> (vármegyék/országok)</> : filter?.mezokod == 2 ? <> <Icons.HomeOutlined /> Születési helység</> :
                          filter?.mezokod == 3 ? <><Icons.GlobalOutlined /> Működési hely<br></br>(vármegye/ország)</> : filter?.mezokod == 4 ? <><Icons.GlobalOutlined /> Működési helység</>
                            : filter?.mezokod == 5 ? <><Icons.FolderOutlined /> Foglalkozás</> : filter?.mezokod == 6 ? <><Icons.FolderOutlined /> Oktatási tevékenység</>
                              : filter?.mezokod == 7 ? <><Icons.BookOutlined /> Tudományos fokozat</> : filter?.mezokod == 8 ? <><Icons.BookOutlined /> Akadémiai tagság</>
                                : filter?.mezokod == 9 ? <><Icons.UserOutlined /> Névváltozatok </> : filter?.mezokod == 10 ? <><Icons.UserOutlined /> Álnevek</> : filter?.mezokod == 11 ? <><Icons.UserOutlined /> Adateltérések</>
                                  : filter?.mezokod == 12 ? <><Icons.MenuOutlined /> Egyéb adatok</> : filter?.mezokod == 13 ? <><Icons.NumberOutlined /> Hivatkozások </> : filter?.mezokod == 14 ? <><Icons.TrophyOutlined /> Kitüntetések </> : filter?.mezokod}
                      </Typography.Text>
                    </Space>
                  </Space>
                }
                bordered={false}
                style={{ height: "100%" }}
              >
                <Space
                  direction="vertical"
                  style={{ width: "100%", height: "100%" }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      textAlign: xl ? "unset" : "center",
                    }}
                  >

                  </Space>
                </Space>
              </Card>
            }
          >

          </Tooltip>
          <Form.Item>
            {" "}
            {!disabled && (
              <Button
                icon={<Icons.FolderAddOutlined />}
                onClick={() => {
                  filterSelectShow();
                }}
                
              ></Button>
            )}

            <Space>
              <Input style={{ width: "170%", left: "40px" }} value={filter?.tnev} />
            </Space>
          </Form.Item>
        </Space>

        <Modal
          width={1200}
          {...filterSelectModal}
          onCancel={filterSelectClose}
          onOk={() => {
            change(selectedRowKeys);
          }}
        >
          <List
            title="Lista"
            resource="data"
            headerProps={{
              extra: <CreateButton hidden={true} />,
            }}
          >

            <Filter2 formProps={searchFormProps2} />

            {/*<Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
                Reload
        </Button>
        */}
            <Table
              {...tableProps}
              rowKey="id"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
            >
              <Table.Column
                dataIndex="mezokod"
                key="mezokod"
                title="Típus"
                render={(value) => <TextField value={
                  value == 1 ? "Születési hely (vármegyék/országok)" : value == 2 ? "Születési helység" :
                    value == 3 ? "Működési hely (vármegye/ország)" : value == 4 ? "Működési helység"
                      : value == 5 ? "Foglalkozás" : value == 6 ? "Oktatási tevékenység"
                        : value == 7 ? "Tudományos fokozat" : value == 8 ? "Akadémiai tagság"
                          : value == 9 ? "Névváltozatok" : value == 10 ? "Álnevek" : value == 11 ? "Adateltérések"
                            : value == 12 ? "Egyéb adatok" : value == 13 ? "Hivatkozások" : value == 14 ? "Kitüntetések" : value
                } />}
              />

              <Table.Column
                dataIndex="tnev" title="Megnevezés" defaultSortOrder={getDefaultSortOrder("tnev", sorter)}
                sorter render={(value) => <TextField value={value} />} />

              <Table.Column
                dataIndex="darab" title="Darab" render={(value) => <TextField value={value} />} />
            </Table>
          </List>
        </Modal>
      </>
    );
  };
  export const Filter2: React.FC<{ formProps: FormProps }> = (props) => {
    const { Option } = Select;
    const t = useTranslate();
    const [value, setValue] = useState(1);
    const form = props?.formProps?.form;//Form.useForm();

    const onFilterChange = (value: any) => {
      switch (value) {
        case 1:
          form?.setFieldsValue({ MezokodExact: 1 });
          break;
        case 2:
          form?.setFieldsValue({ MezokodExact: 2 });
          break;
        case 3:
          form?.setFieldsValue({ MezokodExact: 3 });
          break;
        case 4:
          form?.setFieldsValue({ MezokodExact: 4 });
          break;
        case 5:
          form?.setFieldsValue({ MezokodExact: 5 });
          break;
        case 6:
          form?.setFieldsValue({ MezokodExact: 6 });
          break;
        case 7:
          form?.setFieldsValue({ MezokodExact: 7 });
          break;
        case 8:
          form?.setFieldsValue({ MezokodExact: 8 });
          break;
        case 9:
          form?.setFieldsValue({ MezokodExact: 9 });
          break;
        case 10:
          form?.setFieldsValue({ MezokodExact: 10 });
          break;
        case 11:
          form?.setFieldsValue({ MezokodExact: 11 });
          break;
        case 12:
          form?.setFieldsValue({ MezokodExact: 12 });
          break;
        case 13:
          form?.setFieldsValue({ MezokodExact: 13 });
          break;
        case 14:
          form?.setFieldsValue({ MezokodExact: 14 });
          break;

        default:
      }
    };



    const onFinish = (values: any) => {
      console.log(values);
    };
    const onChange = (e: RadioChangeEvent) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
    };
    return (
      <>
      <Row gutter={[20, 20]} align="top">
      <Form layout="vertical" form={form} {...props.formProps}>
          
          <Col xl={24}>
          <Text strong >Megnevezés keresése</Text>
            <Form.Item style={{paddingTop:10}} name="tnev" >
              <Input
                style={{ width: 250 }}
                allowClear
                placeholder="Bármely előfordulás"
                prefix={<Icons.SearchOutlined />}

              />
            </Form.Item>
            
            
            <Form.Item style={{paddingTop:10}} name="TNameStartsWith" >
              <Input
                style={{ width: 250 }}
                allowClear
                placeholder="Mező elejével egyező"
                prefix={<Icons.SearchOutlined />}

              />
            </Form.Item>
          
            <Form.Item style={{paddingTop:10}} name="TNameExact" >
              <Input
                style={{ width: 250 }}
                allowClear
                placeholder="Teljes egyezés"
                prefix={<Icons.SearchOutlined />}

              />
            </Form.Item>

            <Form.Item name="MezokodExact">
              <Input
                hidden
                allowClear
                placeholder="Megnevezés"
                prefix={<Icons.SearchOutlined />}

              />
            </Form.Item>
          </Col>

     
          <Col xl={12}>
            <Text strong >Szűkítés</Text>
            <Form.Item name="filter">
              <Select
                style={{ width: 250 , paddingTop:10}}
                placeholder="Válassza ki a szűrési feltételt!"
                onChange={onFilterChange}
                allowClear
              >
                <Option value={1}>Születési hely (vármegye/ország)</Option>
                <Option value={2}>Születési hely (helység)</Option>
                <Option value={3}>Működési hely (vármegye/ország)</Option>
                <Option value={4}>Működési hely (helység)</Option>
                <Option value={5}>Foglalkozás</Option>
                <Option value={6}>Oktatási tevékenység</Option>
                <Option value={7}>Tudományos fokozat</Option>
                <Option value={8}>Akadémiai tagság</Option>
                <Option value={9}>Névváltozatok</Option>
                <Option value={10}>Álnevek</Option>
                <Option value={11}>Adateltérések</Option>
                <Option value={12}>Egyéb adatok</Option>
                <Option value={13}>Hivatkozások</Option>
                <Option value={14}>Kitüntetések</Option>
              </Select>
            </Form.Item>
          </Col>
      

        
            <Col xs={24} xl={24} md={8}>
              <Form.Item>
                <Space>
                  <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                    Szűrés</Button>
                </Space>
              </Form.Item>
            </Col>
          </Form>
      
          </Row>

      </>
    );
  };