export const mezoKodTipus =
[
    {
        value: 1,
        label: "Születési hely (megyék/országok)"
    },
    {
        value: 2,
        label: "Születési helység"
    },
    {
        value: 3,
        label: "Működési hely (megye/ország)"
    },
    {
        value: 4,
        label: "Működési helység"
    },
    {
        value: 5,
        label: "Foglalkozás"
    },
    {
        value: 6,
        label: "Oktatási tevékenység"
    },
    {
        value: 7,
        label: "Tudományos fokozat"
    },
    {
        value: 8,
        label: "Akadémia tagság"
    },
    {
        value: 9,
        label: "Névváltozatok",
    },
    {
        value: 10,
        label: "Álnevek"
    },
    {
        value: 11,
        label: "Adateltérések"
    },
    {
        value: 12,
        label: "Egyéb adatok"
    },
    {
        value: 13,
        label: "Hivatkozások"
    },
    {
        value: 14,
        label: "Kitüntetések"
    },
];

export const tnevek = 
[
    {
        value: 1
    }
]