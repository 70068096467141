import { Button, Col, Form, FormProps, Icons, Input, Row, Space } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

export const Filter: React.FC<{ formProps: FormProps }> = (props) => {
    const t = useTranslate();

    return (
      <>
        <Form layout="vertical" {...props.formProps}>
          <Row gutter={20} align="bottom">

            <Col xs={24} lg={20}>
              <Form.Item label="Keresett megnevezés" name="tnev">
                <Input
                  allowClear
                  placeholder="Megnevezés"
                  prefix={<Icons.SearchOutlined />}
                />
              </Form.Item>
            </Col>       
          </Row>
          <Row gutter={[10, 40]} align="bottom">
            <Col xs={24} xl={24} md={8}>
              <Form.Item>
                <Space>
                  <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                    Keresés
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };