import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  useNavigation,
  usePermissions,
  CrudFilters,
  HttpError,
  useShow,
  useUpdate,
} from "@pankod/refine-core";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import React from 'react';
import { mezoKodTipus } from "constants/type";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  FilterDropdown,
  ShowButton,
  AntdList,
  useModalForm,
  Modal,
  Form,
  Radio,
  FormProps,
  Icons,
  Drawer,
  Show,
  useDrawerForm,
  Switch,
  useModal,
  RefreshButton,
  CreateButton,
  Grid,
  AutoComplete,
  Create,
  Edit,
  useStepsForm,
  SaveButton,
  Steps
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { Filter2, FilterSelect } from "components/filterSelect/filterSelect";
import { IPost, ICategory, IOptions } from "interfaces";
import { Col, Row, Tooltip } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';
import { DatePicker, Image } from 'antd';
import { Cascader, InputNumber } from 'antd';
import { UserOutlined, AntDesignOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UploadOutlined, FormOutlined, Loading3QuartersOutlined, CheckOutlined, CheckCircleFilled, CheckCircleOutlined, ExceptionOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { IMekdata0, IMekdata0Filter } from "interfaces/mekdata0";
import { IMekdata2, IMekdata2Filter } from "interfaces/mekdata2";
import { useList } from "@pankod/refine-core";
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Filter } from "components/filterForName/filter";
import { PeopleShow } from "components/peopleShow/peopleShow";
export const Search: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { Meta } = Card;
  const { Option } = Select;
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const { Step } = Steps;

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
  } = useModalForm<IMekdata0>({
    action: "edit",
  });

  const {
    formProps: stepsForm,
    saveButtonProps: saveButtonSteps,
    current,
    gotoStep,
    stepsProps,
    queryResult: querySteps,
  } = useStepsForm<IMekdata0>()
  const [selected, setSelected] = useState(null);
  const handleChange2 = (selectedOption: any) => {
    setSelected(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const [inputV, setInputV] = useState('');

  const handleInputChange = (event: any) => {
    // 👇 Get input value from "event"
    setInputV(event.target.value);
  };

  const formList = [

    <>
      <Row gutter={30}>
        <Col xs={24} lg={30}>
          <br></br>
          <Form.Item label="Név" name="nev">
            <Input />
          </Form.Item>
          <Form.Item label="Születési info" name="sztext">
            <Input placeholder="Nincs adat" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={30}>

          <Form.Item label="Születési év" name="szev">
            <InputNumber placeholder="Nincs adat" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label="Hónap" name="szho">
            <Input placeholder="Nincs adat" />
          </Form.Item>

          <Form.Item label="Nap" name="sznap">
            <Input placeholder="Nincs adat" />
          </Form.Item>

        </Col>
      </Row>


    </>,

    <Row gutter={30}>
      <Col xs={24} lg={30}>
        <br></br>
        <Form.Item label="Halálozási info" name="htext">
          <Input placeholder="Nincs adat" />
        </Form.Item>
        <Form.Item label="Halálozási év" name="hev">
          <Input placeholder="Nincs adat" />
        </Form.Item>
      </Col>
      <Col xs={24} lg={30}>
        <Form.Item label="Hónap" name="hho">
          <Input placeholder="Nincs adat" />
        </Form.Item>

        <Form.Item label="Nap" name="hnap">
          <Input placeholder="Nincs adat" />
        </Form.Item>
      </Col>

    </Row>,

    <>

      <Row gutter={20}>
        <Col span={20} xs={24} lg={30}>
          <br></br>
          <Form.List
            name="peopleDatas"
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{
                      display: "grid",
                      marginBottom: 8,
                      marginLeft: 50,
                      marginRight: 50,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "id"]}
                      fieldKey={[field.key, "id"]}
                     hidden
                    >
                      <Input />
                    </Form.Item>
                    <Row gutter={20}>
                      <Col xs={24} lg={12}>
                      <>
                        <Form.Item
                          label="Megnevezés"
                          name={[field.name, "data"]}>
                          <FilterSelect onSelect={(value:any)=>
                          {
                            //alert(JSON.stringify(value?.mezokod));
                            
                            editFormProps?.form?.setFieldValue(["peopleDatas",field.name,"mezokod"],value?.mezokod);                        
                            //alert(editFormProps?.form?.getFieldValue([field.name,"mezokod"]));
                            
                        }}/>
                       </Form.Item>
                        
                      </>
                      </Col>

                      <Col xs={24} lg={12}>
                        <Form.Item 
                         label="Mezőkód"
                         name={[ field.name, "mezokod"]}
                       >
                          <Select         
                            tabIndex={1}
                            options={mezoKodTipus.map((c) => ({
                              label: c.label,
                              value: c.value,
                            }))}
                          />
                          
                       </Form.Item>
                      </Col>
                    </Row>
                    <Icons.MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                    Tulajdonság hozzáadása
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  ]
  const { queryResult, showId, setShowId } = useShow<IMekdata0>({
    resource: "peoples",
  });
  const { Title, Text: Text2 } = Typography;

  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { show: modalShow, modalProps } = useModal();
  const [visible, setVisible] = useState(false);
  const columns = [
    {
      title: "Megnevezés típusa",
      dataIndex: "mezokod",
      key: "mezokod",
      render: (text: any, data: any) => data?.mezokod == 1 ? "Születési hely (vármegyék/országok)" : data?.mezokod == 2 ? "Születési helység" :
        data?.mezokod == 3 ? "Működési hely (vármegye/ország)" : data?.mezokod == 4 ? "Működési helység"
          : data?.mezokod == 5 ? "Foglalkozás" : data?.mezokod == 6 ? "Oktatási tevékenység"
            : data?.mezokod == 7 ? "Tudományos fokozat" : data?.mezokod == 8 ? "Akadémiai tagság"
              : data?.mezokod == 9 ? "Névváltozatok" : data?.mezokod == 10 ? "Álnevek" : data?.mezokod == 11 ? "Adateltérések"
                : data?.mezokod == 12 ? "Egyéb adatok" : data?.mezokod == 13 ? "Hivatkozások" : data?.mezokod == 14 ? "Kitüntetések" : data?.mezokod
      //JSON.stringify(record)//
    },
    {
      title: "Megnevezés",
      dataIndex: "data.id",
      key: "data.id",
      render: (text: any, data: any) => data?.data.tnev,
      //JSON.stringify(record)//
    },

  ];
  const { tableProps: tableProps2, sorter: sorter2, filters: filters2, searchFormProps: searchFormProps2 } =
    useTable<IMekdata2, HttpError, IMekdata2Filter>({
      resource: "data",
      initialSorter: [
        {
          field: "mezokod",
          order: "desc",
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { mezokod, tnev } = params;

        filters.push({
          field: "mezokod",
          operator: "eq",
          value: mezokod || undefined,
        });

        filters.push({
          field: "tnev",
          operator: "eq",
          value: tnev || undefined,
        });

        return filters;
      },
      syncWithLocation: true,
    });

  const { tableProps, sorter, filters, searchFormProps } =
    useTable<IMekdata0, HttpError, IMekdata0Filter>({
      resource: "peoples",
      initialSorter: [
        {
          field: "szev",
          order: "asc",
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { nev, hev, hho, hnap, szev, szho, sznap, mek1Tnev, mek1mezokod, ktars } = params;

        filters.push({
          field: "nev",
          operator: "eq",
          value: nev || undefined,
        });

        filters.push({
          field: "szev",
          operator: "eq",
          value: szev || undefined,
        });

        filters.push({
          field: "szho",
          operator: "eq",
          value: szho || undefined,
        });

        filters.push({
          field: "sznap",
          operator: "eq",
          value: sznap || undefined,
        });

        filters.push({
          field: "hev",
          operator: "eq",
          value: hev || undefined,
        });

        filters.push({
          field: "hho",
          operator: "eq",
          value: hho || undefined,
        });

        filters.push({
          field: "hnap",
          operator: "eq",
          value: hnap || undefined,
        });

        filters.push({
          field: "peopleDatas.tnev",
          operator: "eq",
          value: mek1Tnev || undefined,
        });

        filters.push({
          field: "peopleDatas.mezokod",
          operator: "eq",
          value: mek1mezokod || undefined,
        });

        filters.push({
          field: "ktars",
          operator: "eq",
          value: ktars,
        });

        return filters;
      },
      syncWithLocation: true,
    });

  const { formProps, drawerProps, show, saveButtonProps } = useDrawerForm<
    IPost,
    HttpError,
    IPost
  >({
    action: "create",
    resource: "peoples"
  });


  const { useBreakpoint } = Grid;
  const { Text, Link } = Typography;
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const onChange2 = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue2(e.target.value);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title="Szűrő" style={{ borderColor: "#001529" }}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <List
            canCreate
            createButtonProps={{
              onClick: () => {
                show();
              },
            }}
            title={<Title level={3}>Keresések</Title>}>
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="id" title="Azonosító"
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter />
              <Table.Column<IMekdata0>
                dataIndex="nev"
                title="Személy neve"
                render={(_, record) => (
                  record.nev == null ? "Nincs adat" : record.nev
                )}
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="szev"
                title="Születési év"
                render={(_, record) => (
                  record.szev == null ? "Nincs adat" : record.szev
                )} 
                defaultSortOrder={getDefaultSortOrder("szev", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="szho"
                title="Hónap"
                render={(_, record) => (
                  record.szho == null ? "Nincs adat" : record.szho
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="sznap"
                title="Nap"
                render={(_, record) => (
                  record.sznap == null ? "Nincs adat" : record.sznap
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="hev"
                title="Halálozási dátum"
                render={(_, record) => (
                  record.hev == null ? "Nincs adat" : record.hev
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="hho"
                title="Hónap"
                render={(_, record) => (
                  record.hho == null ? "Nincs adat" : record.hho
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                dataIndex="hnap"
                title="Nap"
                render={(_, record) => (
                  record.hnap == null ? "Nincs adat" : record.hnap
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata0>
                title="Műveletek"
                dataIndex="actions"
                render={(_, record) => (
                  <Space>
                    <EditButton
                      size="small"

                      resourceNameOrRouteName="peoples"
                      recordItemId={record.id}
                      onClick={() => editModalShow(record.id)}
                    />
                    <ShowButton
                      size="small"
                      resourceNameOrRouteName="peoples"
                      recordItemId={record.id}
                      onClick={() => {
                        setShowId(record.id);
                        setVisible(true);
                      }}
                    />
                    {/*<DeleteButton size="small"
                      resource="peoples"
                      hideText
                      recordItemId={record.id} />*/}
                  </Space>
                )}
              />
            </Table>
          </List>


          {/* --------------- Személyek megtekintése --------------- */}
          <Modal visible={visible} closable={false} width={1000} footer={null}>
            <Show isLoading={isLoading}
              headerButtons={<RefreshButton hidden />}
              headerProps={{
                onBack: () => setVisible(false)
              }}
              resource="peoples"
              recordItemId={showId}>
              <Row gutter={30}>
                <Col xs={24} lg={12}>
                  <Title level={5}>Név</Title>
                  <Text2>{record?.nev}</Text2>
                  <Title level={5}>Születési év</Title>
                  <Text2>{record?.szev == 0 ? "Nincs adat" : record?.szev}</Text2>

                  <Title level={5}>Hónap</Title>
                  <Text2>{record?.szho == null ? "Nincs adat" : record?.szho}</Text2>

                  <Title level={5}>Nap</Title>
                  <Text2>{record?.sznap == null ? "Nincs adat" : record?.sznap}</Text2>
                </Col>
                <Col xs={24} lg={12}>
                  <Title level={5}>Halálozási év</Title>
                  <Text2>{record?.hev == null ? "Nincs adat" : record?.hev}</Text2>

                  <Title level={5}>Hónap</Title>
                  <Text2>{record?.hho == null ? "Nincs adat" : record?.hho}</Text2>

                  <Title level={5}>Nap</Title>
                  <Text2>{record?.hnap == null ? "Nincs adat" : record?.hnap}</Text2>
                </Col>
              </Row>
              <br></br>
              <Table
                pagination={false}
                columns={columns}
                rowKey="id"
                dataSource={record?.peopleDatas}
              />
            </Show>
          </Modal>
          {/* --------------- Személyek megtekintése --------------- */}



          {/* --------------- Személyek szerkesztése --------------- */}


          <Modal afterClose={() => {gotoStep(current -2)}} 
          
            {...editModalProps} >
            <>
              <Edit title="" footerButtons={
                <>
                  {current > 0 && (
                    <Button
                      onClick={() => {
                        gotoStep(current - 1);
                      }}
                    >
                      Előző
                    </Button>
                  )}
                  {current < formList.length - 1 && (
                    <Button
                      onClick={() => {
                        gotoStep(current + 1);
                      }}
                    >
                      Következő
                    </Button>
                  )}
                  {/*{current === formList.length - 1 && (
                        <SaveButton {...saveButtonSteps} />
                  )}*/}
                </>
              } >
                <Steps {...stepsProps}>
                  <Step title="Születési adatok" />
                  <Step title="Halálozási adatok" />
                  <Step title="Tulajdonságok" />
                </Steps>
                <Form  {...editFormProps} onFinish={(values) => {
                  values = editFormProps.form?.getFieldsValue(true);
                  return editFormProps.onFinish?.(values);
                }} layout="vertical">
                  {formList[current]}
                </Form>
              </Edit>
            </>
          </Modal>
          {/* --------------- Személyek szerkesztése --------------- */}


          <Drawer {...drawerProps}>
            <Create saveButtonProps={saveButtonProps}>
              <Form {...formProps} layout="vertical">
                <Col xs={24} lg={16}>
                  <Form.Item label="Név" name="nev" rules={[{ required: true, message: 'A név kitöltése kötelező!' }]}>
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Születési év" name="szev">
                    <InputNumber style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Hónap" name="szho">
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Nap" name="sznap">
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                </Col>
                <Col xs={24} lg={16}>
                  <Form.Item label="Halálozási év" name="hev">
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Hónap" name="hho">
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Nap" name="hnap">
                    <Input style={{ width: 370 }} />
                  </Form.Item>

                  <Form.Item label="Kortárs" name="ktars">
                    <Radio.Group value={value2} onChange={onChange2} >
                      <Radio value={true}>Igen</Radio>
                      <Radio value={false}>Nem</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="Születési és halálozási info megadása">
                    <Radio.Group value={value} defaultValue={4} onChange={onChange} >
                      <Radio value={3}>Igen</Radio>
                      <Radio value={4}>Nem</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <div>
                    {value == 3 ?
                      <>
                        <Form.Item label="Születési info" name="sztext">
                          <Input style={{ width: 370 }} />
                        </Form.Item>

                        <Form.Item label="Halálozási info" name="htext">
                          <Input style={{ width: 370 }} />
                        </Form.Item>
                      </>
                      :
                      <>

                      </>}
                  </div>
                </Col>
              </Form>
            </Create>
          </Drawer>
        </Col>
      </Row>
    </>
  );

};

