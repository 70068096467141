import { useForm, Form, Input, Edit, useSelect, Col, InputNumber, Radio, RadioChangeEvent, Typography, Row, useStepsForm, Steps, Button, SaveButton, Space, Icons, Grid, useTable, useModal, Tooltip, Card, Modal, List, CreateButton, FormProps, Table, TextField } from "@pankod/refine-antd";
import { CrudFilters, HttpError, useShow, useTranslate } from "@pankod/refine-core";
import { IMekdata0 } from "interfaces/mekdata0";
import { IMekdata2, IMekdata2Filter } from "interfaces/mekdata2";
import { SetStateAction, useEffect, useState } from "react";
import { mezoKodTipus } from "constants/type";
import { Select } from 'antd';

const { Step } = Steps;

export const SearchEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<IMekdata0>();
    const { Option } = Select;

    const { selectProps: categorySelectProps } = useSelect<IMekdata0>({
        resource: "peoples",
    });


    const { queryResult: queryShow } = useShow<IMekdata0>();
    const { data, isLoading } = queryShow;
    const record = data?.data;
    const { Title, Text } = Typography;

    const [valueTkod, setValue] = useState(1);

    const {
        formProps: stepsForm,
        saveButtonProps: saveButtonSteps,
        current,
        gotoStep,
        stepsProps,
        queryResult: querySteps,
    } = useStepsForm<IMekdata0>();

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const [selected, setSelected] = useState(null);
    const handleChange2 = (selectedOption: any) => {
        setSelected(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };
    const { useBreakpoint } = Grid;
    const t = useTranslate();

    const {
        selectProps,
        queryResult: dataQuery,
        defaultValueQueryResult
    } = useSelect<IMekdata2>({
        resource: "data",
        hasPagination: true,
        pagination: {
            pageSize: 10000
        },
        onSearch: (value) => [
            {
                field: "searchName",
                operator: "eq",
                value,
            },
        ],
    });

    const allOptions = [
        ...(dataQuery.data?.data || []),
        ...(defaultValueQueryResult.data?.data || []),
    ];

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const formList = [
        <>

            <Row gutter={30}>
                <Col xs={24} lg={12}>
                    <Form.Item label="Név" name="nev">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Születési info" name="sztext">
                        <Input placeholder="Nincs adat"/>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>

                    <Form.Item label="Születési év" name="szev">
                        <Input placeholder="Nincs adat"/>
                    </Form.Item>

                    <Form.Item label="Hónap" name="szho">
                        <Input placeholder="Nincs adat"/>
                    </Form.Item>

                    <Form.Item label="Nap" name="sznap">
                        <Input placeholder="Nincs adat"/>
                    </Form.Item>

                </Col>
            </Row>
        </>,
        <Row gutter={30}>
            <Col xs={24} lg={12}>
                <Form.Item label="Halálozási info" name="htext">
                    <Input placeholder="Nincs adat"/>
                </Form.Item>
                <Form.Item label="Halálozási év" name="hev">
                    <Input placeholder="Nincs adat"/>
                </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
                <Form.Item label="Hónap" name="hho">
                    <Input placeholder="Nincs adat"/>
                </Form.Item>

                <Form.Item label="Nap" name="hnap">
                    <Input placeholder="Nincs adat"/>
                </Form.Item>
            </Col>
        </Row>,

        <>
            <Row gutter={20}>
                <Col span={20} xs={24} lg={30}>
                    <Form.List
                        name="peopleDatas"
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <Space
                                        key={field.key}
                                        style={{
                                            display: "grid",
                                            marginBottom: 8,
                                            marginLeft: 50,
                                            marginRight: 50,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "id"]}
                                            fieldKey={[field.key, "id"]}
                                            hidden
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Row gutter={20}>
                                            <Col xs={24} lg={12}>
                                                <Form.Item
                                                    {...field}
                                                    label="Mezőkód"
                                                    name={[field.name, "mezokod"]}
                                                    shouldUpdate
                                                >
                                                    <Select
                                                        placeholder="Válassza ki a típust!"
                                                        onChange={handleChange2}
                                                        tabIndex={1}
                                                        options={mezoKodTipus.map((c) => ({
                                                            label: c.label,
                                                            value: c.value,
                                                        }))}
                                                        
                                                    />
                                                    
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                {/*<Form.Item
                                                    {...field}
                                                    label="Megnevezés"
                                                    name={[field.name, "data", "id"]}
                                                >
                                                    
                                                    <Select
                                                        tabIndex={2}
                                                        {...selectProps}
                                                        options={allOptions.map((p) => ({
                                                            label: `${p.tnev}`,
                                                            value: p["id"],
                                                        }))}
                                                    />
                                                    </Form.Item>*/}
                                                <Form.Item
                                                    label="Megnevezés"
                                                    name={[field.name, "data"]}>
                                                    <FilterSelect />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Icons.MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                                        Tulajdonság hozzáadása
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </>
    ]

    type FilterSelectProps = {
        value?: IMekdata2;
        onChange?: (filter?: IMekdata2) => void;
        disabled?: boolean;
    };

    /**
     * @param value initialValue passed by parent Form.Item.
     * @param onChange a callback for Form.Item to read its child component's value.
     * @param disabled
     * @constructor
     */
    const FilterSelect: React.FC<FilterSelectProps> = ({
        value,
        onChange,
        disabled,
    }) => {
        const t = useTranslate();
        const [filter, setFilter] = useState<IMekdata2 | undefined>(value);
        const { tableProps, sorter, filters, searchFormProps: searchFormProps2 } = useTable<
            IMekdata2,
            HttpError,
            IMekdata2Filter
        >({
            resource: "data",
            initialSorter: [
                {
                    field: "id",
                    order: "desc",
                },
            ],

            onSearch: (params) => {
                const filters: CrudFilters = [];
                const { tnev, mezokod } = params;

                filters.push({
                    field: "tnev",
                    operator: "eq",
                    value: tnev,
                });

                filters.push({
                    field: "mezokod",
                    operator: "eq",
                    value: mezokod,
                });

                return filters;
            },
            syncWithLocation: false,
        });
        const { xl } = useBreakpoint();
        const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
            value?.id ?? "",
        ]);

        const onSelectChange = (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
        };

        const start = () => {
            setLoading(true);
            // ajax request after empty completing
            setTimeout(() => {
                setSelectedRowKeys([]);
                setLoading(false);
            }, 1000);
        };

        const [loading, setLoading] = useState(false);

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        const {
            modalProps: filterSelectModal,
            show: filterSelectShow,
            close: filterSelectClose,
        } = useModal();

        const { setShowId, queryResult: filterQueryResult } = useShow<IMekdata2>({
            resource: "data",

            id: filter?.id,
        });

        let promise = new Promise(function (resolve, reject) {
            if (typeof onChange === "function") {
                resolve(onChange(filter));
            } else {
                resolve(true);
            }
        });
        useEffect(() => {
            if (value && filter?.id !== value?.id) {
                setFilter(value);
            }
        }, [value]);

        useEffect(() => {
            if (filterQueryResult.isFetched) {
                setFilter(filterQueryResult.data?.data);
            }
        }, [filterQueryResult]);

        useEffect(() => {
            if (filterQueryResult.isFetched && !filterQueryResult.isError) {
                promise.then(filterSelectClose);
            }
        }, [filter]);

        const hasSelected = selectedRowKeys.length > 0;

        const change = async (selectedRowKeys: React.Key[]) => {
            if (filter?.id !== selectedRowKeys[0]) setShowId(selectedRowKeys[0]);
            else filterSelectClose();
        };

        return (
            <>
                <Space>
                    <Tooltip
                        title={
                            <Card
                                title={
                                    <Space
                                        direction="vertical"
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >

                                        <Space
                                            direction="vertical"
                                            style={{
                                                width: "100%",
                                                textAlign: xl ? "unset" : "center",
                                            }}
                                        >
                                            <Typography.Text>
                                                {filter?.mezokod == 1 ? <> <Icons.HomeOutlined /> Születési hely<br></br> (vármegyék/országok)</> : filter?.mezokod == 2 ? <> <Icons.HomeOutlined /> Születési helység</> :
                                                    filter?.mezokod == 3 ? <><Icons.GlobalOutlined /> Működési hely<br></br>(vármegye/ország)</> : filter?.mezokod == 4 ? <><Icons.GlobalOutlined /> Működési helység</>
                                                        : filter?.mezokod == 5 ? <><Icons.FolderOutlined /> Foglalkozás</> : filter?.mezokod == 6 ? <><Icons.FolderOutlined /> Oktatási tevékenység</>
                                                            : filter?.mezokod == 7 ? <><Icons.BookOutlined /> Tudományos fokozat</> : filter?.mezokod == 8 ? <><Icons.BookOutlined /> Akadémiai tagság</>
                                                                : filter?.mezokod == 9 ? <><Icons.UserOutlined /> Névváltozatok </> : filter?.mezokod == 10 ? <><Icons.UserOutlined /> Álnevek</> : filter?.mezokod == 11 ? <><Icons.UserOutlined /> Adateltérések</>
                                                                    : filter?.mezokod == 12 ? <><Icons.MenuOutlined /> Egyéb adatok</> : filter?.mezokod == 13 ? <><Icons.NumberOutlined /> Hivatkozások </> : filter?.mezokod == 14 ? <><Icons.TrophyOutlined /> Kitüntetések </> : filter?.mezokod}
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                }
                                bordered={false}
                                style={{ height: "100%" }}
                            >
                                <Space
                                    direction="vertical"
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: "100%",
                                            textAlign: xl ? "unset" : "center",
                                        }}
                                    >

                                    </Space>
                                </Space>
                            </Card>
                        }
                    >

                    </Tooltip>
                    <Form.Item>
                            {" "}
                            {!disabled && (
                                <Button
                                    icon={<Icons.FolderAddOutlined />}
                                    onClick={() => {
                                        filterSelectShow();
                                    }}
                                ></Button>
                            )}
                        
                            <Space>
                                <Form.Item name="">
                                    <Text style={{marginLeft:15}}>{filter?.tnev}</Text>
                                </Form.Item>
                            </Space>
                    </Form.Item>
                </Space>

                <Modal
                    width={1200}
                    {...filterSelectModal}
                    onCancel={filterSelectClose}
                    onOk={() => {
                        change(selectedRowKeys);
                    }}
                >
                    <List
                        title="Lista"
                        resource="data"
                        headerProps={{
                            extra: <CreateButton hidden={true} />,
                        }}
                    >

                        <Filter2 formProps={searchFormProps2} />

                        {/*<Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
                      Reload
              </Button>
              */}
                        <Table
                            {...tableProps}
                            rowKey="id"
                            rowSelection={{
                                type: "radio",
                                ...rowSelection,
                            }}
                        >
                            <Table.Column
                                dataIndex="mezokod"
                                key="mezokod"
                                title="Típus"
                                render={(value) => <TextField value={
                                    value == 1 ? "Születési hely (vármegyék/országok)" : value == 2 ? "Születési helység" :
                                        value == 3 ? "Működési hely (vármegye/ország)" : value == 4 ? "Működési helység"
                                            : value == 5 ? "Foglalkozás" : value == 6 ? "Oktatási tevékenység"
                                                : value == 7 ? "Tudományos fokozat" : value == 8 ? "Akadémiai tagság"
                                                    : value == 9 ? "Névváltozatok" : value == 10 ? "Álnevek" : value == 11 ? "Adateltérések"
                                                        : value == 12 ? "Egyéb adatok" : value == 13 ? "Hivatkozások" : value == 14 ? "Kitüntetések" : value
                                } />}
                            />

                            <Table.Column
                                dataIndex="tnev" title="Megnevezés" render={(value) => <TextField value={value} />} />

                            <Table.Column
                                dataIndex="darab" title="Darab" render={(value) => <TextField value={value} />} />
                        </Table>
                    </List>
                </Modal>
            </>
        );
    };

    const Filter2: React.FC<{ formProps: FormProps }> = (props) => {
        const { Option } = Select;
        const t = useTranslate();
        const [value, setValue] = useState(1);
        const form = props?.formProps?.form;//Form.useForm();

        const onFilterChange = (value: any) => {
            switch (value) {
                case 1:
                    form?.setFieldsValue({ mezokod: 1 });
                    break;
                case 2:
                    form?.setFieldsValue({ mezokod: 2 });
                    break;
                case 3:
                    form?.setFieldsValue({ mezokod: 3 });
                    break;
                case 4:
                    form?.setFieldsValue({ mezokod: 4 });
                    break;
                case 5:
                    form?.setFieldsValue({ mezokod: 5 });
                    break;
                case 6:
                    form?.setFieldsValue({ mezokod: 6 });
                    break;
                case 7:
                    form?.setFieldsValue({ mezokod: 7 });
                    break;
                case 8:
                    form?.setFieldsValue({ mezokod: 8 });
                    break;
                case 9:
                    form?.setFieldsValue({ mezokod: 9 });
                    break;
                case 10:
                    form?.setFieldsValue({ mezokod: 10 });
                    break;
                case 11:
                    form?.setFieldsValue({ mezokod: 11 });
                    break;
                case 12:
                    form?.setFieldsValue({ mezokod: 12 });
                    break;
                case 13:
                    form?.setFieldsValue({ mezokod: 13 });
                    break;
                case 14:
                    form?.setFieldsValue({ mezokod: 14 });
                    break;

                default:
            }
        };



        const onFinish = (values: any) => {
            console.log(values);
        };
        const onChange = (e: RadioChangeEvent) => {
            console.log('radio checked', e.target.value);
            setValue(e.target.value);
        };
        return (
            <>
                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                >
                    <br></br>
                    <Text><strong>Szűkítés</strong></Text>
                    <Form.Item name="filter">
                        <Select
                            style={{ width: 250 }}
                            placeholder="Válassza ki a szűrési feltételt!"
                            onChange={onFilterChange}
                            allowClear
                        >
                            <Option value={1}>Születési hely (vármegye/ország)</Option>
                            <Option value={2}>Születési hely (helység)</Option>
                            <Option value={3}>Működési hely (vármegye/ország)</Option>
                            <Option value={4}>Működési hely (helység)</Option>
                            <Option value={5}>Foglalkozás</Option>
                            <Option value={6}>Oktatási tevékenység</Option>
                            <Option value={7}>Tudományos fokozat</Option>
                            <Option value={8}>Akadémiai tagság</Option>
                            <Option value={9}>Névváltozatok</Option>
                            <Option value={10}>Álnevek</Option>
                            <Option value={11}>Adateltérések</Option>
                            <Option value={12}>Egyéb adatok</Option>
                            <Option value={13}>Hivatkozások</Option>
                            <Option value={14}>Kitüntetések</Option>
                        </Select>
                    </Form.Item>

                </Form>

                <Form layout="vertical" form={form} {...props.formProps}>
                    <Row gutter={[20, 20]} align="top">
                        <Col xs={24} xl={24} md={12}>
                            <Form.Item name="tnev" >
                                <Input
                                    style={{ width: 250 }}
                                    allowClear
                                    placeholder="Megnevezés"
                                    prefix={<Icons.SearchOutlined />}

                                />
                            </Form.Item>
                            <Form.Item name="mezokod">
                                <Input
                                    hidden
                                    allowClear
                                    placeholder="Megnevezés"
                                    prefix={<Icons.SearchOutlined />}

                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[10, 40]} align="bottom">
                        <Col xs={24} xl={24} md={8}>
                            <Form.Item>
                                <Space>
                                    <Button style={{ width: "100%" }} htmlType="submit" type="primary">
                                        {t("buttons.filter")}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    };

    return (
        <>
            <Edit footerButtons={
                <>
                    {current > 0 && (
                        <Button
                            onClick={() => {
                                gotoStep(current - 1);
                            }}
                        >
                            Előző
                        </Button>
                    )}
                    {current < formList.length - 1 && (
                        <Button
                            onClick={() => {
                                gotoStep(current + 1);
                            }}
                        >
                            Következő
                        </Button>
                    )}
                    {current === formList.length - 1 && (
                        <SaveButton {...saveButtonSteps} />
                    )}
                </>
            } >
                <Steps {...stepsProps}>
                    <Step title="Születési adatok" />
                    <Step title="Halálozási adatok" />
                    <Step title="Tulajdonságok" />
                </Steps>
                <Form {...stepsForm} layout="vertical" style={{ marginTop: 30 }}>
                    {formList[current]}
                </Form>
            </Edit>
        </>
    );
};