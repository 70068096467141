import React from "react";

import { AuthProvider, Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";
import { ConfigProvider } from 'antd';
import hu_HU from 'antd/locale/hu_HU';
import simpleRestDataProvider from "./dataProvider";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { ColorModeContextProvider } from "contexts";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { Search } from "pages/kereses/list";
import { SearchShow } from "pages/kereses/show";
import { SearchEdit } from "pages/kereses/edit";
import { Login } from "pages/login";
import { DataList } from "pages/tulajdonsagok/list";

function App() {
  const { t, i18n } = useTranslation();
  //const API_URL = "http://127.0.0.1:8000/api";
  const API_URL = "/api"
  const dataProvider = simpleRestDataProvider(API_URL);


  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const mockUsers = [
    {
      username: "admin",
      password: "admin",
      roles: ["admin"],
    }
  ];
  const authProvider: AuthProvider = {
    login: ({ username, password, remember }) => {
      // Suppose we actually send a request to the back end here.

      const user = mockUsers.find((item) => item.username === username && item.password === password);

      if (user) {
        localStorage.setItem("auth", JSON.stringify(user));
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => {
      const auth = localStorage.getItem("auth");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.roles);
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem("auth");
      return Promise.resolve();
    },
    checkError: (error) => {
      if (error && error.statusCode === 401) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    checkAuth: () =>
      localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
    getUserIdentity: () => {
      const auth = localStorage.getItem("auth");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.username);
      }
      return Promise.reject();
    },
  };
  return (
    <ColorModeContextProvider>
      <ConfigProvider locale={hu_HU}>
        <Refine
          authProvider={authProvider}
          dataProvider={dataProvider}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          Title={Title}
          LoginPage={Login}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={Layout}
          OffLayoutArea={OffLayoutArea}
          routerProvider={routerProvider}
          i18nProvider={i18nProvider}
          resources={[
            {
              name:"peoples",
              list: Search,
              show: SearchShow,
              edit: SearchEdit
            },
            {
              name:"data",
              list: DataList
            }
          ]}
        />
      </ConfigProvider>
    </ColorModeContextProvider>
  );
}

export default App;
