import { Show, Typography, Tag, Row, Col, Table, useTable, Space, Icons, Button } from "@pankod/refine-antd";
import { useOne, useShow } from "@pankod/refine-core";
import { IMekdata0 } from "interfaces/mekdata0";
import { IMekdata1 } from "interfaces/mekdata1";
import { Link, Route, Routes } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react'

const { Title, Text: Text2 } = Typography;

export const SearchShow = () => {
    const { queryResult } = useShow<IMekdata0>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { tableProps, sorter, filters } = useTable<IMekdata1>({
        resource: "peoples"
    });

    const columns = [
        {
            title: "Megnevezés típusa",
            dataIndex: "mezokod",
            key: "mezokod",
            render: (text: any, data: any) => data?.mezokod == 1 ? "Születési hely (vármegyék/országok)" : data?.mezokod == 2 ? "Születési helység" :
                data?.mezokod == 3 ? "Működési hely (vármegye/ország)" : data?.mezokod == 4 ? "Működési helység"
                    : data?.mezokod == 5 ? "Foglalkozás" : data?.mezokod == 6 ? "Oktatási tevékenység"
                        : data?.mezokod == 7 ? "Tudományos fokozat" : data?.mezokod == 8 ? "Akadémiai tagság"
                            : data?.mezokod == 9 ? "Névváltozatok" : data?.mezokod == 10 ? "Álnevek" : data?.mezokod == 11 ? "Adateltérések"
                                : data?.mezokod == 12 ? "Egyéb adatok" : data?.mezokod == 13 ? "Hivatkozások" : data?.mezokod == 14 ? "Kitüntetések" : data?.mezokod
            //JSON.stringify(record)//
        },
        {
            title: "Megnevezés",
            dataIndex: "data.id",
            key: "data.id",
            render: (text: any, data: any) => data?.data.tnev,
            //JSON.stringify(record)//
        },

    ];

    return (
        <Show isLoading={isLoading}>
               <Row gutter={30}>
                <Col xs={24} lg={12}>
                        <Title level={5}>Név</Title>
                        <Text2>{record?.nev}</Text2>
                        <Title level={5}>Születési év</Title>
                        <Text2>{record?.szev == 0 ? "Nincs adat" : record?.szev}</Text2>
                        
                        <Title level={5}>Hónap</Title>
                        <Text2>{record?.szho == null ? "Nincs adat" : record?.szho}</Text2>

                        <Title level={5}>Nap</Title>
                        <Text2>{record?.sznap == null ? "Nincs adat" : record?.sznap}</Text2>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Title level={5}>Halálozási év</Title>
                        <Text2>{record?.hev == null ? "Nincs adat" : record?.hev}</Text2>

                        <Title level={5}>Hónap</Title>
                        <Text2>{record?.hho == null ? "Nincs adat" : record?.hho}</Text2>

                        <Title level={5}>Nap</Title>
                        <Text2>{record?.hnap == null ? "Nincs adat" : record?.hnap}</Text2>
                    </Col>
                </Row>
                <br></br>
                <Table
                    pagination={false}
                    columns={columns}
                    rowKey="id"
                    dataSource={record?.peopleDatas}
                />
        </Show>
    );
};