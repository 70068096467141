import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  useNavigation,
  usePermissions,
  CrudFilters,
  HttpError,
  useShow,
  useUpdate,
} from "@pankod/refine-core";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import React from 'react';
import { mezoKodTipus } from "constants/type";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  FilterDropdown,
  ShowButton,
  AntdList,
  useModalForm,
  Modal,
  Form,
  Radio,
  FormProps,
  Icons,
  Drawer,
  Show,
  useDrawerForm,
  Switch,
  useModal,
  RefreshButton,
  CreateButton,
  Grid,
  AutoComplete,
  Create,
  Edit,
  useStepsForm,
  SaveButton,
  Steps
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import { Filter2, FilterSelect } from "components/filterSelect/filterSelect";
import { IPost, ICategory, IOptions } from "interfaces";
import { Col, Row, Tooltip } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';
import { DatePicker, Image } from 'antd';
import { Cascader, InputNumber } from 'antd';
import { UserOutlined, AntDesignOutlined, InboxOutlined } from '@ant-design/icons';
import { message, Upload, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UploadOutlined, FormOutlined, Loading3QuartersOutlined, CheckOutlined, CheckCircleFilled, CheckCircleOutlined, ExceptionOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { IMekdata0, IMekdata0Filter } from "interfaces/mekdata0";
import { IMekdata2, IMekdata2Filter } from "interfaces/mekdata2";
import { useList } from "@pankod/refine-core";
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Filter } from "components/filterForTnev/filter";
import { PeopleShow } from "components/peopleShow/peopleShow";
export const DataList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { Meta } = Card;
  const { Option } = Select;
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const { Step } = Steps;

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
  } = useModalForm<IMekdata0>({
    action: "edit",
  });

  const [selected, setSelected] = useState(null);
  const handleChange2 = (selectedOption: any) => {
    setSelected(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const [inputV, setInputV] = useState('');

  const handleInputChange = (event: any) => {
    // 👇 Get input value from "event"
    setInputV(event.target.value);
  };

  const { Title, Text: Text2 } = Typography;
  const { tableProps, sorter, filters, searchFormProps } =
    useTable<IMekdata2, HttpError, IMekdata2Filter>({
      resource: "data",
      initialFilter: [{ field: "mezokod", operator: "eq", value: 13 }],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { mezokod, tnev } = params;

        /*filters.push({
          field: "mezokod",
          operator: "eq",
          value: mezokod || undefined,
        });*/

        filters.push({
          field: "tnev",
          operator: "eq",
          value: tnev || undefined,
        });

        return filters;
      },
      syncWithLocation: true,
    });

  
  const { formProps, drawerProps, show, saveButtonProps, id } = useDrawerForm<IMekdata2, HttpError, IMekdata2>({
      resource: "data",
      action: "edit",
      warnWhenUnsavedChanges: true,
  });

  const { useBreakpoint } = Grid;
  const { Text, Link } = Typography;
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const onChange2 = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue2(e.target.value);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={24} xs={24}>
          <Card title="Szűrő" style={{ borderColor: "#001529" }}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>

        <Col xl={18} xs={24}>
          <List
            title={<Title level={3}>Források</Title>}>

            <Table {...tableProps} rowKey="id">
              <Table.Column<IMekdata2>
                dataIndex="mezokod"
                title="Mezőkód"
                render={(_, record) => (
                  record?.mezokod == 1 ? "Születési hely (vármegyék/országok)" : record?.mezokod == 2 ? "Születési helység" :
                  record?.mezokod == 3 ? "Működési hely (vármegye/ország)" : record?.mezokod == 4 ? "Működési helység"
                      : record?.mezokod == 5 ? "Foglalkozás" : record?.mezokod == 6 ? "Oktatási tevékenység"
                        : record?.mezokod == 7 ? "Tudományos fokozat" : record?.mezokod == 8 ? "Akadémiai tagság"
                          : record?.mezokod == 9 ? "Névváltozatok" : record?.mezokod == 10 ? "Álnevek" : record?.mezokod == 11 ? "Adateltérések"
                            : record?.mezokod == 12 ? "Egyéb adatok" : record?.mezokod == 13 ? "Hivatkozások" : record?.mezokod == 14 ? "Kitüntetések" : record?.mezokod
                )}
                />
              <Table.Column<IMekdata2>
                dataIndex="tnev"
                title="Megnevezés"
                render={(_, record) => (
                  record.tnev == null ? "Nincs adat" : record.tnev
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata2>
                dataIndex="forrasTeljes"
                title="Forrásnév"
                render={(_, record) => (
                  record.forrasTeljes == null ? "Nincs adat" : record.forrasTeljes == "" ? "Nincs adat" : record.forrasTeljes 
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata2>
                dataIndex="forrasLink"
                title="Forráslink"
                render={(_, record) => (
                  record.forrasLink == null ? "Nincs adat" : record.forrasLink == "" ? "Nincs adat" : record.forrasLink
                )} 
                defaultSortOrder={getDefaultSortOrder("id", sorter)}
                sorter
                />
              <Table.Column<IMekdata2>
                title="Műveletek"
                dataIndex="actions"
                render={(_, record) => (
                  <Space>
                    <EditButton
                      size="small"
                      resourceNameOrRouteName="peoples"
                      recordItemId={record.id}
                      onClick={() => show(record.id)}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>
          
          <Drawer {...drawerProps}>
            <Edit saveButtonProps={saveButtonProps} recordItemId={id}>
              <Form {...formProps} layout="vertical">
                <Form.Item
                  label="Forrásnév"
                  name="forrasTeljes"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Forráslink"
                  name="forrasLink"
                >
                  <Input />
                </Form.Item>
              </Form>
            </Edit>
          </Drawer>

        </Col>
      </Row>
    </>
  );

};

